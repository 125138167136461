<template>
    <div class="cover-password row-center">

        <div class="popup">

            <header>
                <img src="/img/logo-madrid.png" alt="">
            </header>

            <div class="contenedor-input">
                <label for="">Usuario</label>
                <input type="text" v-model="data.eUsuario">
            </div>

            <div class="contenedor-input">
                <label for="">Contraseña</label>
                <input type="password" v-model="data.ePassword">
            </div>

            <a @click="check" class="btn">Enviar</a>

        </div>

    </div>
</template>



<script>


import axios from 'axios';

import router from '@/router';

export default {
    name: 'password',
    mounted() {
        this.requestToken();
        axios.defaults.baseURL = 'https://iamappi.iformalia.es/innoWS.asmx/'
    },
    data: () => ({
        data: {
            ePassword: '',
            eUsuario: ''
        }
    }),
    methods: {
        requestToken() {

            const _0xe5a6 = ["\x50\x51\x68\x71\x37\x61\x6A\x54\x48\x67\x41\x4B\x34\x41\x62\x62\x6A\x32\x34\x5A\x69\x6F\x72\x61\x34\x36\x56\x4D\x72\x6D\x68\x53"];

            axios.post(`RequestToken`, { eToken: _0xe5a6[0] }).then(response => {
                sessionStorage.setItem('TOKEN_PUBLICO', JSON.stringify(response.data));
            }).catch(function (error) {
                console.log(error);
            });
        },
        check() {



            axios.post(`RequestLogin`, this.data).then(response => {
                if (response.data.Token) {
                    window.sessionStorage.setItem('pass', true);
                    router.push({ name: 'Home' })
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>